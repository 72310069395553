<template>
  <div>
    <h2>外延片操作日志</h2>
  </div>
</template>

<script>
export default {
  name: "ProdExtSkuLogs",
  activated() {
    document.title='外延片操作日志'
  }
}
</script>

<style scoped>

</style>
